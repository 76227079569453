import styled from "styled-components";

const OrangeCTAButton = styled.div`
    display: flex;
    align-items: center;
    background: #ff685f;
    padding: 4px 10px 4px 16px;
    border-radius: 90px;
    color: white;
    width: fit-content;
    transition: background 0.5s ease;
    &:hover {
        background:  #ff685f88;
        opacity: 1;
        & svg {
            transform: translateX(3px);
        }
    }
    & svg {
        transition: transform 0.2s ease;
    }
`;

export default OrangeCTAButton;
