import React, {CSSProperties} from "react";
import TextIconWrapper from "./TextIconWrapper";

interface LogoProps {
    style?: CSSProperties;
}

const Logo: React.FC<LogoProps> = ({style}) => {
    return (
        <TextIconWrapper style={style}>
            nmtl
            <span style={{color: '#ff685f', fontWeight: 900}}>.</span>
        </TextIconWrapper>
    )
}

export default Logo;