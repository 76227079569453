import styled from 'styled-components';

export const TextIconWrapper = styled.h2`
    color: #1e1e23;
    text-decoration: none;
    font-weight: 400;
    font-size: 28px;
    margin: 0;
`;

export default TextIconWrapper;