import React from "react";
import CenterPage from "../components/CenterPage";

const ErrorPage: React.FC = () =>
    <CenterPage>
        <h1
            style={{
                fontWeight: 400,
                textAlign: 'center'
            }}
        >
            <span style={{fontSize: 52, color: '#ff685f'}}>404.</span> Page not found
        </h1>
    </CenterPage>

export default ErrorPage;
