import React from "react";
import {Box, Container, Typography} from "@mui/material";

const Banner = () =>
    <Box
        style={{background: '#FBFAFB'}}
        position='relative'
        display='flex'
        alignItems='center'
        height='100%'
        overflow='hidden'
    >
        <Container style={{position: 'relative', zIndex: 10, pointerEvents: 'none'}}>
            <Typography
                variant="h1"
                sx={(theme) => ({
                    textAlign: 'center',
                    fontWeight: 400,
                    fontSize: 62,
                    margin: 0,
                    [theme.breakpoints.down("sm")]: {
                        fontSize: 48,
                    }
                })}
            >
                Creating Solutions<br/> that <span style={{color: '#ff685f'}}>Matter.</span>
            </Typography>
        </Container>
    </Box>

export default Banner;