import styled from "styled-components";

export const CenterPage = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default CenterPage;