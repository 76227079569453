import React from 'react';
import {Container, Grid} from "@mui/material";
import ContactForm from "../../components/forms/ContactForm";
import FullPageBanner from "../../components/FullPageBanner";
import FullImageContainer from "../../components/FullScreenImageContainer";

const ContactPage = () =>
    <FullPageBanner>
        <Grid container direction='row-reverse' style={{position: 'absolute', height: '100%'}}>
            <Grid item md={6} xs={12}>
                <FullImageContainer/>
            </Grid>
        </Grid>
        <Container
            style={{
                position: 'relative'
            }}
        >
            <Grid
                container
                spacing={6}
            >
                <Grid item xs={12} md={6}>
                    <h1 style={{fontWeight: 400, fontSize: 36, marginBottom: 32}}>
                        Say Hello
                        <span style={{color: '#ff685f'}}>.</span>
                    </h1>
                    <ContactForm/>
                </Grid>
            </Grid>
        </Container>
    </FullPageBanner>

export default ContactPage;