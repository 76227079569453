import React from "react";
import {Box, Container, Grid} from "@mui/material";
import NativeLink from "../../components/NativeLink";
import FullPageBanner from "../../components/FullPageBanner";
import cph from "../../assets/medias/contact.png";
import FullScreenImageContainer from "../../components/FullScreenImageContainer";

const AboutPage = () => {

    return (
        <FullPageBanner>
            <Grid container direction='row-reverse' style={{position: 'absolute', height: '100%'}}>
                <Grid item md={6} xs={12}>
                    <FullScreenImageContainer>
                        <Box display='flex'
                             style={{
                                 background: `url(${cph})`,
                                 backgroundSize: 'cover',
                                 borderRadius: 32,
                                 width: 400,
                                 height: 400,
                             }}
                        />
                        <p>
                            Copenhagen based
                        </p>
                    </FullScreenImageContainer>
                </Grid>
            </Grid>
            <Container
                style={{
                    position: 'relative'
                }}
            >
                <Grid
                    container
                    spacing={6}
                >
                    <Grid item xs={12} md={6}>

                        <h1 style={{fontWeight: 400, fontSize: 36, marginBottom: 32}}>
                            Companies
                            <span style={{color: '#ff685f'}}>.</span>
                        </h1>

                        <h1 style={{fontWeight: 400, fontSize: 36, marginBottom: 32}}>
                            Organizations
                            <span style={{color: '#ff685f'}}>.</span>
                        </h1>
                        <NativeLink
                            href='https://itb.dk/voresudvalg/sundheds-it/'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <div style={{
                                border: 'solid 1px rgba(0,0,0,0.1)',
                                padding: 16,
                                borderRadius: 8,
                                color: '#2c273c'
                            }}>
                                Udvalget for Sundheds-IT
                            </div>
                        </NativeLink>
                        <br/>
                        <NativeLink
                            href='https://www.imperial.ac.uk/psychedelic-research-centre/about-us/people/'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <div style={{
                                border: 'solid 1px rgba(0,0,0,0.1)',
                                padding: 16,
                                borderRadius: 8,
                                color: '#2c273c'
                            }}>
                                Centre for Psychedelic Research
                            </div>
                        </NativeLink>
                    </Grid>
                </Grid>
            </Container>
        </FullPageBanner>
    )
};

export default AboutPage;