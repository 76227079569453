import React, {useState} from "react";
import {Box} from "@mui/material";

const getWindowHeight = () => {
    const {innerHeight: height} = window;
    return height;
}

interface FullPageBannerProps {
    minHeight?: number;
    children: React.ReactNode;
}

const FullPageBanner: React.FC<FullPageBannerProps> =
    ({minHeight = 600, children}) => {
        const [height] = useState<number>(getWindowHeight());
        return (
            <Box style={{
                minHeight: minHeight,
                height: height,
                display: 'flex',
                alignItems: 'center',
                background: '#FBFAFB'
            }}>
                {children}
            </Box>
        )
    }

export default FullPageBanner;