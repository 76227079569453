import React from "react";
import {Box, Container} from "@mui/material";
import {Link} from "react-router-dom";
import RouteNames from "../../constants/RouteNames";
import Logo from "../Logo";
import styled from "styled-components";
import OrangeCTAButton from "../buttons/ContactButton";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";

const HeaderWrapper = styled.header`
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1000;
    padding-top: 16px;
`;

const Header = styled.nav`
    display: flex;
    align-items: center;
`;

const HeaderNavigation = () => {
    return (
        <HeaderWrapper>
            <Header>
                <Container maxWidth="xl">
                    <Box display='flex' justifyContent='space-between'>
                        <Link
                            to={RouteNames.landing}
                            style={{textDecoration: 'none'}}
                        >
                            <Logo/>
                        </Link>
                        <Box display='flex' alignItems='center'>
                            <Link
                                to={RouteNames.about}
                                style={{textDecoration: 'none', color: '#1e1e23'}}
                            >About</Link>
                            <div style={{
                                marginLeft: 32
                            }}>
                                <Link
                                    to={RouteNames.contact}
                                    style={{
                                        textDecoration: 'none',
                                        display: 'block',
                                        width: 'fit-content'
                                    }}
                                >
                                    <OrangeCTAButton>
                                        Contact
                                        <ArrowRightSLineIcon size={18} style={{marginLeft: 2, marginTop: 1}}/>
                                    </OrangeCTAButton>
                                </Link>
                            </div>
                        </Box>
                    </Box>
                </Container>
            </Header>
        </HeaderWrapper>
    )
}

export default HeaderNavigation;