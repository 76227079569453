import React from "react";
import {Box, Container} from "@mui/material";
import LinkedinFillIcon from "remixicon-react/LinkedinFillIcon";
import GithubFillIcon from "remixicon-react/GithubFillIcon";
import MailFillIcon from "remixicon-react/MailFillIcon";
import NativeLink from "../NativeLink";
import styled from "styled-components";

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding-bottom: 16px;
`;

const SocialRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
    width: 100%;
  }
`;

const FooterNavigation = () => {
    const d = new Date();
    const n = d.getFullYear();
    return (
        <Footer>
            <Container maxWidth="xl">
                <SocialRow>
                        <span style={{fontSize: 13}}>
                        © {n} NMTL Consulting ApS
                        <span style={{display: "block", fontSize: 10, opacity: 0.8}}>CVR: 39934507</span>
                    </span>
                </SocialRow>
                <SocialRow>
                    <Box pr={2}>
                        <NativeLink
                            href='https://www.linkedin.com/in/nicolai-m-t-lassen/'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <LinkedinFillIcon size={19} color='#2c273c'/>
                        </NativeLink>
                    </Box>
                    <Box pr={2}>
                        <NativeLink
                            href='https://github.com/NicolaiLassen'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <GithubFillIcon size={19} color='#2c273c'/>
                        </NativeLink>
                    </Box>
                    <Box>
                        <NativeLink href='mailto:mail@nmtl.dk'>
                            <MailFillIcon size={18} color='#2c273c'/>
                        </NativeLink>
                    </Box>
                </SocialRow>
            </Container>
        </Footer>
    )
}

export default FooterNavigation;

