import React, {useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {Button, Grid, TextField} from "@mui/material";
import {Alert} from "@mui/lab";

const ContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    message: Yup.string().required('Required')
});

interface ContactValues {
    name: string;
    email: string;
    message: string;
}

const ContactForm = () => {

    const initialValues: ContactValues = {
        name: '',
        email: '',
        message: ''
    }

    const [success, setSuccess] = useState<boolean>(false);

    const encode = (data: Record<string, string>) =>
        Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");

    const handleSubmit = (
        values: ContactValues,
        {resetForm, validateForm}: FormikHelpers<ContactValues>) => {
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({"form-name": "contact-form", ...values})
        })
            .then(() => {
                resetForm();
                validateForm();
                setSuccess(true);
            })
            .catch(error => alert(error));
    }

    return (
        <Formik<ContactValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ContactSchema}
            validateOnMount={true}
        >
            {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid
              }) => (
                <Form
                    name='contact-form'
                    data-netlify='true'
                    data-netlify-recaptcha='false'
                    data-netlify-honeypot='bot-field'
                >
                    <Field type='hidden' name='contact-form'/>
                    <Field type='hidden' name='bot-field'/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                required
                                id='name'
                                label='Name'
                                name='name'
                                variant='outlined'
                                error={errors.name && touched.name}
                                helperText={touched.name ? errors.name : ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                as={TextField}
                                required
                                type='email'
                                label='Email'
                                name='email'
                                variant='outlined'
                                error={errors.email && touched.email}
                                helperText={touched.email ? errors.email : ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                as={TextField}
                                required
                                type='message'
                                label='Your message...'
                                name='message'
                                variant='outlined'
                                fullWidth
                                multiline
                                rows={5}
                                error={errors.message && touched.message}
                                helperText={touched.message ? errors.message : ''}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        color='primary'
                        aria-label='submit'
                        type='submit'
                        variant='contained'
                        disabled={isSubmitting || !isValid}
                        fullWidth={true}
                        style={{
                            boxShadow: 'none',
                            marginTop: 16,
                            marginBottom: 16
                        }}
                    >
                        Submit
                    </Button>
                    {success &&
                        <Alert severity="success"><b>Thank you for reaching out!</b> We will be in contact soon.</Alert>
                    }
                </Form>
            )}
        </Formik>
    )
}

export default ContactForm;