import React from 'react';
import {createBrowserHistory} from 'history';
import ReactGA from "react-ga";
import styled from "styled-components";
import {ThemeProvider} from "@mui/material";
import appTheme from "./theme/appTheme";
import {Route, Routes} from "react-router-dom";
import HeaderNavigation from "./components/navigation/HeaderNavigation";
import FooterNavigation from "./components/navigation/FooterNavigation";
import RouteNames from "./constants/RouteNames";
import AboutPage from "./pages/About";
import LandingPage from "./pages/LandingPage";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import {HistoryRouter} from "./components/HistoryRouter";

ReactGA.initialize("UA-130025366-1");

const history = createBrowserHistory();

history.listen(e => {
    ReactGA.set({page: e.location.pathname});
    ReactGA.pageview(e.location.pathname);
});

const PageWrapper = styled.div`
    flex: 1 0 auto;
    position: relative;
    height: 100vh;
    overflow: hidden;
`;

function App() {
    return (
        <ThemeProvider theme={appTheme}>
            <HistoryRouter history={history}>
                <PageWrapper>
                    <HeaderNavigation/>
                    <Routes>
                        <Route path={RouteNames.landing} element={<LandingPage/>}/>
                        <Route path={RouteNames.about} element={<AboutPage/>}/>
                        <Route path={RouteNames.contact} element={<ContactPage/>}/>
                        <Route path='*' element={<ErrorPage/>}/>
                    </Routes>
                    <FooterNavigation/>
                </PageWrapper>
            </HistoryRouter>
        </ThemeProvider>
    );
}

export default App;
