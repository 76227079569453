import styled from "styled-components";

const FullScreenImageContainer = styled.div`
    height: 100%;
    width: 100%;
    background: #F7F7F8;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export default FullScreenImageContainer;